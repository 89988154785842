import React, { useEffect, useState } from "react"
import FrontmatterPageContext from "~context/FakePageContext"

import { mainArea } from "~vanilla/layout-default-full.css"
import { main } from "~vanilla/homepage.css"
import * as styles from "~vanilla/404.css"

import LayoutCore from "~templates/layout-core"
import { useSwiftype } from "../hooks/use-swiftype"
import { FavHead } from "~components/Head/FavHead"

let frontmatter = {
  section: "none",
  section_safe: "none",
  title: "404 - Fastly Documentation",
  lang: "en",
}

const NotFoundPage = () => {
  const { results, keywords } = useSearchResultsFromPath()
  const showSuggestedResults = results.docs.length > 0 && keywords.length > 0

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                <div>
                  <img
                    src="/assets/images/404.png"
                    style={{ height: "5rem", float: "left", marginTop: ".5rem" }}
                    alt=""
                  />
                  <h1 id="" style={{ marginTop: 0 }}>
                    Oops!{" "}
                    <small>
                      <br />
                      We hate to say it but we couldn't find that page.
                    </small>
                  </h1>
                  If you typed the URL, double-check your spelling or use our search bar to refine your search terms.
                  👆.
                </div>

                {showSuggestedResults ? (
                  <div className={styles.suggestedResults}>
                    <h2>Suggested pages based on terms in this URL: {keywords.map((w) => `[${w}]`).join(" ")}</h2>
                    <ul className={styles.ul}>
                      {results.docs.map((result) => {
                        return (
                          <li className={styles.li}>
                            <h3 className={`${styles.h3} ${styles.searchResultsItemTitle}`}>
                              <a href={result.url}>
                                <span dangerouslySetInnerHTML={{ __html: result.highlight.title || result.title }} />
                              </a>
                            </h3>
                            <div className={`${styles.resultBody} ${styles.searchResultsItem}`}>
                              {result.highlight.body ? (
                                <span dangerouslySetInnerHTML={{ __html: result.highlight.body }} />
                              ) : (
                                <span>{result.body.substring(0, 300)}</span>
                              )}
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ) : null}
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default NotFoundPage

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <FavHead />
    </>
  )
}

function useSearchResultsFromPath() {
  const [keywords, setKeywords] = useState([])

  useEffect(() => {
    const parts = decodeURIComponent(window.location.pathname).split(/[-/_]/)

    // the first one will always be blank, because pathname always starts with a slash
    parts.shift()

    const [firstPart] = parts

    // pop the language
    if (firstPart === "en" || firstPart === "ja") {
      parts.shift()
    }

    // shift these section parts off, but only off the (new) first item, they may be a valid word later
    const sectionList = ["guides", "changes", "products", "account", "compute", "full_site_delivery", "getting_started", "integrations", "security"]
    const [sectionMaybe] = parts
    if (sectionList.includes(sectionMaybe)) {
      parts.shift()
    }

    // tbd, any other weasel words to outright filter out before swift.
    const filterList = []

    const filtered = parts.filter((part) => part !== "" && !filterList.includes(part))

    setKeywords(filtered)
  }, [])

  const { results } = useSwiftype({
    searchQuery: keywords.join(" "),
  })

  return { results, keywords }
}
